<template>
    <b-row>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5" v-hotkey="keymap">
                        <div
                            class="d-sm-flex justify-content-between align-items-center mb-3"
                        >
                            <feather
                                type="arrow-left-circle"
                                v-b-popover.hover.top="'Presione esc'"
                                role="button"
                                class="text-secondary mb-2"
                                size="2rem"
                                @click="back"
                            ></feather>
                            <div>
                                <b-button
                                    variant="secondary"
                                    :disabled="isLoading"
                                    v-b-popover.hover.top="'Presione esc'"
                                    size="md"
                                    class="mr-4"
                                    @click="back"
                                >
                                    Cancelar
                                </b-button>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">
                                    Ver registro de la auditoría
                                </h3>
                                <div class="text-gray mb-5">
                                    Este registro corresponde a la actividad de
                                    los usuarios dentro del software
                                </div>
                            </div>
                        </div>
                        <b-row
                            class="my-1"
                            v-for="(label, index) in Object.keys(auditoria)"
                            :key="index"
                        >
                            <b-col cols="12" sm="6">
                                <label :for="`${label}`"
                                    ><code
                                        class="text-black font-weight-bold"
                                        >{{ label }}</code
                                    >:</label
                                >
                            </b-col>
                            <b-col
                                cols="12"
                                sm="6"
                                v-if="!checkObjectField(auditoria[label])"
                            >
                                <span :id="`${label}`">{{
                                    auditoria[label]
                                }}</span>
                            </b-col>
                            <b-col cols="12" sm="6" v-else>
                                <json-viewer
                                    :value="auditoria[label]"
                                    sort
                                    show-array-index
                                    theme="json-viewer-theme"
                                ></json-viewer>
                            </b-col>
                        </b-row>
                        <div class="float-right">
                            <b-button
                                variant="secondary"
                                :disabled="isLoading"
                                size="md"
                                class="mt-4 mr-4"
                                v-b-popover.hover.top="'Presione esc'"
                                @click="back"
                            >
                                Cancelar
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters } from 'vuex'

const { API } = services

const defaultAuditoria = () =>
    JSON.parse(
        JSON.stringify({
            ip: '',
            data: {},
            fecha: '',
            accion: '',
            modulo_id: 0,
            empresa_id: 0,
            usuario_id: 0,
            computer_name: {},
            data_anterior: {}
        })
    )

export default {
    name: 'VerAuditoría',
    data: () => ({
        auditoria: defaultAuditoria(),
        isLoading: false
    }),
    mounted() {
        this.getParams()
    },
    methods: {
        checkObjectField(value) {
            return typeof value === 'object'
        },
        getParams() {
            const { auditoria_id } = this.$route.params
            if (auditoria_id) {
                // this.getAuditoriaById(auditoria_id)
                // const { id, ...rest } = {
                //     id: 60,
                //     ip: '179.12.136.171',
                //     data: {
                //         id: 5,
                //         puc: {
                //             id: 2617,
                //             tipo: 'M',
                //             codigo: '001',
                //             enabled: true,
                //             created_at: '2023-02-09T20:23:28',
                //             empresa_id: 7,
                //             updated_at: '2023-02-09T20:23:36',
                //             descripcion: 'Ejemplo puc update',
                //             iva_retencion: 'I',
                //             maneja_tercero: true,
                //             porcentaje_base: 10,
                //             maneja_centro_costo: false,
                //             maneja_doc_referencia: false
                //         },
                //         tipo: 'A',
                //         codigo: '0070',
                //         banco_id: 1,
                //         empresa_id: 7,
                //         num_cuenta: '1111',
                //         descripcion: 'cb'
                //     },
                //     fecha: '2023-03-16T19:32:08.140315',
                //     accion: 'INSERT',
                //     modulo_id: 22,
                //     empresa_id: 7,
                //     usuario_id: 1,
                //     computer_name: {
                //         brands: [
                //             { brand: 'Google Chrome', version: 111 },
                //             { brand: 'Not(A:Brand', version: 8 },
                //             { brand: 'Chromium', version: 111 }
                //         ],
                //         mobile: false,
                //         platform: 'macOS'
                //     },
                //     data_anterior: {}
                // }
                // this.auditoria = { ...rest }
                // id // this id is out of te render for security
                this.getAuditoriaById(auditoria_id)
            }
        },
        getAuditoriaById(id) {
            API.POST({
                url: 'seguridad/auditoria/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: { usuario_id: this.getUserData.id }
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        const { id, fecha, ...rest } = dato
                        id
                        const [full_fecha, full_hora] = fecha.split('T')
                        full_fecha
                        const d = new Date(fecha)
                        const [hora] = full_hora.split('.')
                        const [hour, seconds] = hora.split(':')
                        d.setHours(Number(hour) - 5, seconds, 0)
                        const obj = JSON.parse(
                            JSON.stringify({
                                fecha: `${new Date(d)
                                    .toLocaleString()
                                    .split('/')
                                    .join('-')}`,
                                ...rest
                            })
                        )
                        obj.computer_name = JSON.parse(obj.computer_name)

                        this.auditoria = { ...obj }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        back() {
            this.$router.back()
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    esc: () => this.$router.back()
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    esc: () => this.$router.back()
                }
            } else {
                return {}
            }
        },
        ...mapGetters('usuario', ['getUserData']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
